import toastStore from '@/stores/toast-store.js'

export default {
  name: 'ToastRoot',
  data: function () {
    return {
      toasts: [],
    }
  },
  watch: {
    listenForStoreChanges() {},
  },
  computed: {
    listenForStoreChanges() {
      this.toasts = toastStore.state.toasts
    },
  },
  methods: {
    destroyToast: function (id) {
      console.log('destroying', id)
      toastStore.commit('destroyToast', id)
    },
  },
  components: {
    Toast: () => import('@/vcs/_shared/toast.vue'),
  },
}
