import Vue from 'vue'
import Router from 'vue-router'

import BackOffice from './vcs/backoffice/cmp.vue'
import Login from './vcs/login/cmp.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/back-office/:id',
      name: 'BackOffice',
      component: BackOffice,
    },
    {
      path: '/configuration/:id',
      name: 'FrontOffice',
      component: () => import('./vcs/frontoffice/cmp.vue'),
    },
    {
      path: '/configuration/:id/edit',
      name: 'Configuration',
      component: () => import('./vcs/backoffice/configuration/cmp.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!['Login', 'FrontOffice'].includes(to.name) && !window.localStorage.getItem('pzpn_user_uid'))
    next({ name: 'Login' })
  next()
})

export default router
