<template lang="pug">
#app
  router-view
  modal-container
  toasts-container
</template>

<style lang="scss">
@import '@/assets/styles/main.scss';
</style>

<script>
import ModalContainer from '@/vcs/_shared/modal-container/cmp.vue'
import ToastsContainer from '@/vcs/_shared/toasts-container/cmp.vue'

export default {
  components: {
    ModalContainer,
    ToastsContainer,
  },
}
</script>
