import { getFirestore } from 'firebase/firestore'
import * as firebase from 'firebase/app'

var firebaseConfig = {
  // apiKey: 'AIzaSyAVDySds-81u-te8I2N-1JFXDBkq5VbX-Y',
  // authDomain: 'pzpn-lottery.firebaseapp.com',
  // databaseURL: 'https://pzpn-lottery.firebaseio.com',
  // projectId: 'pzpn-lottery',
  // storageBucket: 'pzpn-lottery.appspot.com',
  // messagingSenderId: '4394152772',

  apiKey: 'AIzaSyAiD2ZyET2Ratsa8OSctSDIPW8v-DJMNHA',
  authDomain: 'eventdressingdraws.firebaseapp.com',
  databaseURL: 'https://eventdressingdraws.firebaseio.com',
  projectId: 'eventdressingdraws',
  storageBucket: 'eventdressingdraws.appspot.com',
  messagingSenderId: '966160996131',
  // appId: ?
}

const app = firebase.initializeApp(firebaseConfig)
const db = getFirestore(app)

console.log('firebase.js', app)
console.log('firebase.js', db)

export { app, db }
