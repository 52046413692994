import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: '',
  },
  mutations: {
    // TODO: Remove?
    setToken(state, token) {
      state.token = token
      window.localStorage.setItem('pzpn_token', token)
    },
    setUser(state, user) {
      state.user = user
      window.localStorage.setItem('pzpn_user_uid', user.uid)
    },
    // TODO: Remove?
    deleteToken(state) {
      state.token = ''
      window.localStorage.removeItem('pzpn_token')
    },
    deleteUser(state) {
      state.user = null
      window.localStorage.removeItem('pzpn_user_uid')
    },
  },
  actions: {},
})
