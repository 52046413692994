import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modalStore = new Vuex.Store({
  state: {
    cmps: [],
  },
  mutations: {
    open(state, payload) {
      state.cmps.push({
        cmp: payload.cmp,
        animation: payload.animation,
        style: payload.style,
        data: payload.data,
        size: payload.size,
        callback: payload.callback,
        isVisible: true,
      })
    },

    close(state, payload) {
      !!state.cmps[payload.index].callback && payload.data
        ? state.cmps[payload.index].callback(payload.data)
        : null
      state.cmps.splice(payload.index, 1)
    },

    closeAll(state) {
      state.cmps = []
    },
  },
  getters: {
    isOpen: function (state) {
      return state.isOpen
    },
  },
})

export default modalStore
