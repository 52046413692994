import { Edit3Icon, PlusSquareIcon, Trash2Icon } from 'vue-feather-icons'
import { collection, getDocs, doc, deleteDoc, setDoc } from 'firebase/firestore'

import modalStore from '@/stores/modal-store.js'
import AddConfigModal from '@/vcs/_shared/modals/add-configuration-modal/cmp.vue'

export default {
  name: 'BackOfficeView',
  data: function () {
    return {
      blocker: false,
      configurations: [],
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    edit(uid) {
      this.$router.push(`/configuration/${uid}/edit`)
    },
    async toggleActive(config) {
      await setDoc(doc(this.$db, 'configurations', config.uid), config)
    },
    addItem() {
      modalStore.commit('open', {
        cmp: AddConfigModal,
        animation: 'slide-left-modal',
        callback: this.fetchData,
      })
    },
    async deleteItem(uid) {
      await deleteDoc(doc(this.$db, 'configurations', uid))
      this.fetchData()
    },
    async fetchData() {
      // https://firebase.google.com/docs/firestore/query-data/get-data
      const querySnapshot = await getDocs(collection(this.$db, 'configurations'))
      const configurations = querySnapshot.docs.map(document => ({
        ...document.data(),
        uid: document.id,
      }))
      const userConfigurations = configurations.filter(
        config => config.userUid === window.localStorage.getItem('pzpn_user_uid'),
      )
      this.configurations = userConfigurations
    },
    logout() {
      this.$store.commit('deleteUser')
      this.$store.commit('deleteToken')
      this.$router.push('/')
    },
  },
  components: {
    Trash2Icon,
    Edit3Icon,
    PlusSquareIcon,
    Blocker: () => import('@/vcs/_shared/blocker/cmp.vue'),
  },
}
