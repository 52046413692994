import BaseModal from '@/vcs/_shared/modals/base-modal.vue'
import { addDoc, collection } from 'firebase/firestore'

export default {
  name: 'AddConfigurationModal',
  extends: BaseModal,
  data: function () {
    return {
      config: {
        name: '',
        edition: '',
        active: false,
      },
    }
  },
  mounted() {
    // edit
    if (!!this.data && !!this.data.config) {
      this.config.name = this.data.config.name
      this.config.edition = this.data.config.edition
      this.config.active = this.data.config.active
    }
  },
  methods: {
    async save() {
      if (this.data?.config) {
        this.close({ state: 'confirm', data: { ...this.config } })
      } else {
        const obj = {
          id: `${Math.random().toString(36).substr(2, 20)}`,
          userUid: window.localStorage.getItem('pzpn_user_uid'),
          name: this.config.name,
          edition: this.config.edition,
          active: this.config.active,
          positionArray: ['1', '2', '3', '4'],
          visibleSection: 'none',
          visiblePairing: false,
          teamSelected: {
            id: '',
            name: '',
            name2: '',
            sectionId: '',
            group: '',
            position: '',
          },
          pairings: [],
          sections: [
            {
              id: `${Math.random().toString(36).substr(2, 20)}`,
              name: 'A',
              color: '#73d8ff',
              sort: 'asc',
              teams: [],
              groups: ['A', 'B', 'C', 'D'],
              showDatapicker: false,
            },
          ],
        }
        try {
          const docRef = await addDoc(collection(this.$db, 'configurations'), obj)
          console.log('Document written with ID: ', docRef.id)
          this.close(true)
        } catch (e) {
          console.error('Error adding document: ', e)
        }
      }
    },
  },
}
