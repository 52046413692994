<script>
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

export default {
  name: 'Login',
  mounted() {},
  methods: {
    signIn() {
      const provider = new GoogleAuthProvider()
      const auth = getAuth()

      signInWithPopup(auth, provider)
        .then(result => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
          const user = result.user
          console.log('user', user)

          this.$success({ message: 'Login successful!' })
          this.storeUserData(user, token)
          this.redirectToBackOffice(user.uid)
        })
        .catch(() => {
          this.$error({ message: 'Login failed' })
          // // Handle Errors here.
          // const errorCode = error.code
          // const errorMessage = error.message
          // // The email of the user's account used.
          // const email = error.customData.email
          // // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error)
          // // ...
        })
    },
    storeUserData(user, token) {
      this.$store.commit('setToken', token)
      this.$store.commit('setUser', user)
    },
    redirectToBackOffice(userUid) {
      this.$router.push(`/back-office/${userUid}`)
    },
  },
}
</script>

<template lang="pug">
.col-2.offset-5.h-100vh.d-flex.align-items-center
  button.btn.btn-primary.btn-block(@click="signIn" type="button") Login
</template>

<style scoped lang="scss"></style>
