<script>
export default {
  name: 'BaseModal',
  props: {
    data: Object,
  },
  methods: {
    close(val) {
      this.$emit('close', val)
    },
  },
}
</script>
