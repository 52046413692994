import './registerServiceWorker'

import _ from 'lodash'
import moment from 'moment'
import PrettyCheckbox from 'pretty-checkbox-vue'
import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'
import toastStore from '@/stores/toast-store.js'

import App from './App.vue'
import router from './router'
import store from './store'

import { app, db } from '@/vcs/_shared/firebase.js'

Vue.prototype.$error = payload => {
  toastStore.commit('error', payload)
}

Vue.prototype.$success = payload => {
  toastStore.commit('success', payload)
}

Vue.prototype.$originUrl = () => {
  return window ? window.location.origin : ''
}

Vue.prototype.$app = app
Vue.prototype.$db = db

window._ = _
moment.locale('pl')
window.moment = moment

Vue.config.productionTip = false

// plugins
Vue.use(PrettyCheckbox)
Vue.use(VueLocalStorage)

new Vue({
  name: 'PzpnApp',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
