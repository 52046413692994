import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

const ToastStore = new Vuex.Store({
  state: {
    toasts: [],
  },
  mutations: {
    error(state, payload) {
      if (window._.map(state.toasts, 'message').indexOf(payload.message) == -1) {
        state.toasts.push({
          message: payload.message,
          type: 'error',
          height: payload.height,
          id: payload.id || `${window.moment().unix()}-${parseInt(Math.random() * 1000000)}`,
        })
      }
    },

    warning(state, payload) {
      if (window._.map(state.toasts, 'message').indexOf(payload.message) == -1) {
        state.toasts.push({
          message: payload.message,
          type: 'warning',
          height: payload.height,
          id: payload.id || `${window.moment().unix()}-${parseInt(Math.random() * 1000000)}`,
        })
      }
    },

    success(state, payload) {
      if (window._.map(state.toasts, 'message').indexOf(payload.message) == -1) {
        state.toasts.push({
          message: payload.message,
          type: 'success',
          height: payload.height,
          errors: payload.errors ? payload.errors : null,
          id: payload.id || `${window.moment().unix()}-${parseInt(Math.random() * 1000000)}`,
        })
      }
    },

    destroyToast(state, id) {
      if (id) {
        const i = window._.findIndex(state.toasts, t => {
          return t.id == id
        })
        if (i || i == 0) {
          state.toasts.splice(i, 1)
        }
      }
    },

    clearToasts(state) {
      state.toasts = []
    },
  },
})

export default ToastStore
