import modalStore from '@/stores/modal-store.js'

export default {
  name: 'ModalContainer',
  data: function () {
    return {
      cmps: [],
      indexToRemove: 0,
      payload: null,
    }
  },
  watch: {
    listenForStoreChanges() {},
  },
  computed: {
    listenForStoreChanges() {
      this.cmps = modalStore.state.cmps
    },
    isOpen() {
      return this.cmps.length > 0
    },
  },
  methods: {
    close(payload, index) {
      this.indexToRemove = index
      this.payload = payload

      // animate out
      this.cmps[index].isVisible = false

      // delayed close with confirm or cancel
      if (payload == false || payload.state == 'cancel') {
        setTimeout(this.cancel, 200)
      } else {
        setTimeout(this.confirm, 200)
      }
    },

    confirm() {
      modalStore.commit('close', { index: this.indexToRemove, data: this.payload })
    },

    cancel() {
      modalStore.commit('close', { index: this.indexToRemove, data: null })
    },
  },
}
